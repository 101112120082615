import { defineStore } from 'pinia'

import type {
  AnnouncementList,
  CarouselList,
  Cities,
  CookiesList,
  Countries,
  Districts,
  EventDetail,
  FeaturedList,
  LookupState,
  ObjectRecord,
  Professions,
  PromiseAny,
} from '~/models'

export const useLookupsStore = defineStore('LookupsStore', {
  // LOOKUP STATE
  state: (): LookupState => {
    return {
      cities: [],
      districts: [],
      countries: [],
      professions: [],
      carousels: [],
      features: [],
      eventDetail: {},
      cookies: [],
      announcements: [],
      tableRouteHash: {
        'financial.history': 'OpenOrders',
        'open.orders': 'OpenOrders',
        'order.history': 'OrderHistory',
        'trade.history': 'TradeHistory',
        'crypto.account.history': 'TransferHistory',
        'asset.history': 'TransferHistory',
      },
      statusHash: {
        TransferStatus_WaitingApproval: {
          text: 'financialHistory.waitingApproval',
          icon: 'info-regular',
          class: 't-text-tertiary',
        },
        TransferStatus_Processing: {
          text: 'financialHistory.processed',
          icon: 'info-regular',
          class: 't-text-tertiary',
        },
        TransferStatus_Completed: {
          text: 'financialHistory.completed',
          icon: 'check',
          class: 't-green-dim',
        },
        TransferStatus_Queued: {
          text: 'financialHistory.queued',
          icon: 'info-regular',
          class: 't-text-tertiary',
        },
        TransferStatus_Cancelled: {
          text: 'financialHistory.cancelled',
          icon: 'warn-regular',
          class: 't-red-bright',
        },
        TransferStatus_Failed: {
          text: 'financialHistory.failed',
          icon: 'warn-regular',
          class: 't-red-bright',
        },
        OrderStatus_Filled: {
          text: 'financialHistory.completed',
          icon: 'check',
          class: 't-green-dim',
        },
        OrderStatus_Cancelled: {
          text: 'financialHistory.cancelled',
          icon: 'warn-regular',
          class: 't-red-bright',
        },
        OrderStatus_Expired: {
          text: 'financialHistory.cancelled',
          icon: 'warn-regular',
          class: 't-red-bright',
        },
        OrderStatus_Rejected: {
          text: 'financialHistory.cancelled',
          icon: 'warn-regular',
          class: 't-red-bright',
        },
        open: {
          text: 'financialHistory.active',
          type: 'warning',
        },
        close: {
          text: 'financialHistory.completed',
          type: 'success',
        },
        completed: {
          text: 'financialHistory.completed',
          type: 'success',
        },
        processed: {
          text: 'financialHistory.completed',
          type: 'success',
        },
        unopen: {
          text: 'financialHistory.active',
          type: 'warning',
        },
        pending: {
          text: 'financial_history.pending',
          type: 'warning',
        },
        failed: {
          text: 'financial_history.failed',
          type: 'danger',
        },
        rejected: {
          text: 'financial_history.failed',
          type: 'danger',
        },
        waiting: {
          text: 'financial_history.waiting',
          type: 'warning',
        },
      },
      typeHash: {
        limit: 'financialHistory.limit',
        market: 'financialHistory.market',
        condition: 'financialHistory.condition',
      },
    }
  },

  // LOOKUP ACTIONS
  actions: {
    fetchArticle({ article }: { article: string }): PromiseAny {
      return $fetch(`https://paribu.zendesk.com/api/v2/help_center/articles/${article}`)
    },

    fetchCities() {
      $http.get('contents/cities').then(({ payload }: any) => (this.cities = payload))
    },

    fetchDistricts(city: number): void {
      $http.get(`contents/counties/${city}`).then(({ payload }: any) => (this.districts = payload))
    },

    fetchCountries(): void {
      $http.get('contents/countries').then(({ payload }: any) => (this.countries = payload))
    },

    fetchProfessions(): void {
      $http.get('contents/professions').then(({ payload }: any) => (this.professions = payload))
    },

    fetchCarousels(): void {
      $fetch(`${process.env.NUXT_API_ASSET_BASE_URL}/content/banners.json`).then(
        ({ data }: any) => (this.carousels = data?.payload),
      )
    },

    fetchFeatures(): void {
      $fetch('contents/features').then(({ data }: any) => (this.features = data?.payload))
    },

    fetchCookies(): void {
      $fetch(process.env.NUXT_API_ASSET_BASE_URL + '/content/cookies.json').then(
        ({ data }: any) => (this.cookies = data?.payload),
      )
    },

    fetchAnnouncements(): void {
      $fetch(`${process.env.NUXT_API_ASSET_BASE_URL}/content/announcements.json`).then(
        ({ data }: any) => (this.announcements = data?.payload),
      )
    },

    fetchEventDetail(data: EventDetail): void {
      this.eventDetail = data
    },
  },

  // LOOKUP GETTERS
  getters: {
    getCities: (state: LookupState): Cities => state.cities,

    getDistricts: (state: LookupState): Districts => state.districts,

    getCountries: (state: LookupState): Countries => state.countries,

    getProfessions: (state: LookupState): Professions => state.professions,

    getCarousels: (state: LookupState): CarouselList => state.carousels,

    getFeatures: (state: LookupState): FeaturedList => state.features,

    getEventDetail: (state: LookupState): EventDetail => state.eventDetail,

    getCookies: (state: LookupState): CookiesList => state.cookies,

    getAnnouncements: (state: LookupState): AnnouncementList => state.announcements,

    getTableRouteHash: (state: LookupState): ObjectRecord => state?.tableRouteHash as ObjectRecord,

    getStatusHash: (state: LookupState): ObjectRecord => state?.statusHash as ObjectRecord,

    getTypeHash: (state: LookupState): ObjectRecord => state?.typeHash as ObjectRecord,
  },
})
